import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import getAgencyInfo from "../../apiCalls/getAgencyInfo";
import getAgentInfo from "../../apiCalls/getAgentInfo";
import getEliteStatus from "../../apiCalls/getEliteStatus";
import getFaaPmEditLink from "../../apiCalls/getFaaPmEditLink";
import PageHeader from "../PageHeader/PageHeader";
import AgentCard from "../AgentCard/AgentCard";
import AboutSection from "./AboutSection/AboutSection";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import "./AgencyInfoPage.css";
import { Content, GridRow, GridCol, Heading, Caption, Link, Alert, Card} from "@lmig/lmds-react";
import { Pagination } from '@lmig/lmds-react-pagination';
import OfficeLocation from "../OffliceLocation/OfficeLocation";
import MobileNav from "../MobileNav/MobileNav";
import { fixCase, nameCase, formatAgencyName, formatAgencyOperatingHours, formatPhone } from "../../helpers/StringFormatter";
import Reviews from "../Reviews/Reviews"
import { AppContext } from "../../App";
import { findPlaceFromPhoneNumber } from "../../apiCalls/googleServices"
import { Constants } from "../../helpers/Constants";
import { CircularProgressSpinner } from '@lmig/sales-ui-components';
import { Helmet, HelmetProvider} from 'react-helmet-async'; 

function AgencyInfoPage() {
  let [agency, setAgency] = useState<any>();
  let [agents, setAgents] = useState<any>([]);
  let [elite, setElite] = useState<boolean>(false);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [faapmAgencyEditLink, setfaapmAgencyEditLink] = useState<any>();
  let [agentListLoaded, setAgentListLoaded] = useState<boolean>(false);
  let [agentListLength, setAgentListLength] = useState<Number>(0);
  /*app context */
  const context = useContext(AppContext);
  let [place, setPlace] = useState<google.maps.places.PlaceResult>();
  let [error, setError] = useState<{ wasreturned: boolean, message?: string }>({ wasreturned: false });
  /*pagination */
  const [itemCount, setItemCount] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [itemsPerPage] = React.useState(5);

  // TODO: probably want to sanitize these params? or does useParams do that??
  const params: any = useParams();

  const isAnIframe = () => window.self !== window.top;

  useEffect(() => {
    //id you have react dev tools installed as a chrome extension this useEffect may cause unexpected issues
    if (context.agency)
      setAgency(context.agency)
  }, [context.agency]);

  const structuredData = () =>{
    /* developers.google.com/search/docs/advanced/structured-data/local-business */
    let data =
    [ {
      "@context": "https://schema.org",
      "@type": "InsuranceAgency",
      "image": `/FAANG_App_Svc/resources/blobs/${agency?.logos?.blob_id}`,
      "name": `${formatAgencyName(agency.agencyModel.agencyPrimaryName)} - Safeco Insurance Agency, ${fixCase(agency?.agencyModel?.agencyCity)}, ${agency?.agencyModel?.agencyState}`,
      "description": `${agency?.about}`,
      "address": {
        "@type": "PostalAddress",
        "streetAddress": `${fixCase(agency?.agencyModel?.agencyAddress1)}`,
        "addressLocality": `${fixCase(agency?.agencyModel?.agencyCity)}`,
        "addressRegion": `${agency?.agencyModel?.agencyState}`,
        "postalCode": `${agency?.agencyModel?.agencyZip}`,
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": `${agency?.agencyModel?.lat}`,
        "longitude": `${agency?.agencyModel?.lng}`
      },
      "url": `${agency?.agencyModel?.agencyWebsite}`,
      "telephone": `${agency.agencyModel.agencyAreaCode + agency.agencyModel.agencyPhone}`,
    }, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": {
      "@type": "Question",
      "name": "What are our hours?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `${formatAgencyOperatingHours(agency.operatingTimes)}`
      }
      }
    } ]

    return JSON.stringify(data);
  }

  const agencyDescription = () => {
    return `Call ${formatAgencyName(agency?.agencyModel?.agencyPrimaryName)} at ${formatPhone(agency?.agencyModel?.agencyAreaCode + agency?.agencyModel?.agencyPhone)}, a Safeco independent agency, in ${fixCase(agency?.agencyModel?.agencyCity)}, ${agency?.agencyModel?.agencyState} for a quote today!`
  }
  const imageUrl = () => {
    return `${agency.logos.blob_id? window.location.host + `/FAANG_App_Svc/resources/blobs/`+ agency.logos.blob_id : window.location.host + "/find-an-insurance-agency/favicon.ico"}`
  }

  const changeHandler = (e:any) => {
    setCurrentPage(e.page);
  };

  useEffect(() => {
    window.scrollTo(0,0);

    async function getPlace(phoneNumber: string, address: string) {
      phoneNumber = phoneNumber.replace(new RegExp(/(\D+)/g), "");
      let data = await findPlaceFromPhoneNumber(phoneNumber) as google.maps.places.PlaceResult[];
      for (var i = 0; i < data.length; i++) {
        //make sure the place found has some components of the address in common with what we have stored for that agency's address
        //if we update this logic, faa-pm-client should be updated with the same
        if (data[i] && (data[i].formatted_address ?? "").toLocaleLowerCase().indexOf(address.split(' ').slice(0,2).join(' ').toLocaleLowerCase()) > -1) {

          if (data[i].rating && data[i].user_ratings_total)
            setPlace(data[i]);
        }
      }
    }
    async function initInfo() {
      setIsLoading(true);
      if (params.id !== context?.agency?.agencyModel?.agencyID)
        setAgency(null);
      let res = params.id === context?.agency?.agencyModel?.agencyID || context?.agency?.preview || isAnIframe() ? context.agency : await getAgencyInfo(params.id);
      if (res && !(res.statusText === "Internal Server Error" || res.statusText === "No Content")) {
        if (res.agencyModel.agentModel)
        {
          let allAgents : any[] = [];
          let tempAgents : any[] = [];

          Promise.allSettled(res.agencyModel.agentModel.map((agent :any)=>{
            return getAgentInfo(agent.agencyID, agent.agentID);
          })).then((resultArr)=>{
            resultArr.forEach((result:any)=>{
              if(result.status!== "rejected"){
                tempAgents.push(result.value);
              }
            })
            Promise.allSettled(tempAgents.map((tempAgent : any)=>{
              return getAgentInfo(tempAgent.agentModel.agencyID, tempAgent.agentModel.agentID);
            })).then((resArr)=>{
                resArr.forEach((updatedAgent)=>{
                  if(updatedAgent.status !== "rejected" && (updatedAgent.value.agentModel !== undefined && updatedAgent.value.agentModel !== null) ){
                    allAgents.push(updatedAgent.value);
                  }
              })

              allAgents = allAgents.sort((a: any, b: any) => a.agentModel.name.localeCompare(b.agentModel.name));
              allAgents = allAgents.sort((a: any, b: any) => {
                if (parseInt(a.agentModel.position) === -1 && parseInt(b.agentModel.position) === -1)
                  return 0; //keep original order of a and b
                else if (parseInt(a.agentModel.position) === -1)
                  return 1; //sort b before a
                else if (parseInt(b.agentModel.position) === -1)
                  return -1; //sort a before b
                else if (parseInt(a.agentModel.position) < parseInt(b.agentModel.position))
                  return -1; //sort a before b
                else
                  return 1; //sort b before a
              })

              setAgents(allAgents);
              setAgentListLoaded(true);
            })
          })
        }
        if (context?.agency?.agencyModel?.agencyID !== res?.agencyModel?.agencyID)
          context.setAgency(res);
        setAgency(res);
        if (context.place)
          setPlace(context.place);
        else if (res?.agencyModel?.agencyAreaCode && res?.agencyModel?.agencyPhone)
          getPlace(`${res?.agencyModel?.agencyAreaCode}${res?.agencyModel?.agencyPhone}`, res?.agencyModel?.agencyAddress1);

        document.title = formatAgencyName(res?.agencyModel.agencyPrimaryName);
        let agencyID: string[] = [res?.agencyModel.agencyID.substring(8)];
        getEliteStatus(agencyID).then(res => res.json()).then((obj: any) => {
          if (obj.details && obj.details.data.find((x: any) => x.final_Tier == "Elite")) //eslint-disable-line eqeqeq
            setElite(true);
        })
        getFaaPmEditLink().then(res=>res.json()).then((obj)=>obj.details).then((resultArray)=>{
          resultArray.forEach((result:any)=>{
            if(result.label === "agencyUrl"){
              setfaapmAgencyEditLink(result.url);
            }
          })          
        })
      }
      else if (res && res.statusText === "Internal Server Error")
        setError({ wasreturned: true, message: "An error occured while looking up this agency profile. Please try again later." })

      else if (res && res.statusText === "No Content")
        setError({ wasreturned: true, message: "No content was found for this agency." })
      setIsLoading(false);
    }

    initInfo();

  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(agents.length > 1){
      setItemCount(agents.length);
    }
    
    setAgentListLength(agency?.agencyModel?.agentModel?.length? agency.agencyModel.agentModel.length : 0);
    
  }, [agents, setItemCount, agentListLength, setAgentListLength, agency])

  const renderPagination = () => {
    if((agency?.agencyModel?.agentModel?.length? agency.agencyModel.agentModel.length : 0) > itemsPerPage){
      return <Pagination itemCount={itemCount} itemsPerPage={itemsPerPage} withWords={false} onChange={changeHandler} page={currentPage} showItemsPerPage={false}/>
    }else{
      return null;
    }
  }

  const renderPlaceholderCards = () => {
    let arr: boolean[] = [];
    for(let i = 0; i < agentListLength && i < 5; i++){
      arr.push(true)
    }
    return arr.map((agent: boolean)=>
      <Card className="placeholder-card"/>
    )
  }

  return (
    <div>
      {!error.wasreturned
        ? <>
            <Content className="full-bleed">
              {isLoading ? <CircularProgressSpinner aria-label="Loading agency profile" size="64" /> : null}
              {agency?.agencyModel
                ? <>
                    {!agency.preview ? <Breadcrumb searchResults="" currentPage={formatAgencyName(agency.agencyModel.agencyPrimaryName)} /> : null}
                    <HelmetProvider>
                      <Helmet>
                        <script type="application/ld+json">{structuredData()}</script>
                        <meta name="description"               content={agencyDescription()} />
                        <meta property="og:url"                content={window.location.href} />
                        <meta property="og:title"              content={formatAgencyName(agency.agencyModel.agencyPrimaryName)}/>
                        <meta property="og:description"        content={agencyDescription()} />
                        <meta property="og:image"              content={imageUrl()} />
                      </Helmet>
                    </HelmetProvider>
                    <PageHeader
                      agencyID={agency.agencyModel.agencyID}
                      elite={elite}
                      name={formatAgencyName(agency.agencyModel.agencyPrimaryName)}
                      about={agency.about}
                      addressOne={fixCase(agency.agencyModel.agencyAddress1)}
                      addressTwo={`${fixCase(agency.agencyModel.agencyCity)}, ${agency.agencyModel.agencyState} ${agency.agencyModel.agencyZip}`}
                      phone={agency.agencyModel.agencyAreaCode + agency.agencyModel.agencyPhone}
                      blobId={agency.logos.blob_id}
                      email={agency.agencyModel.agencyEmail}
                      website={agency.agencyModel.agencyWebsite}
                      operatingTimes={agency.operatingTimes}
                      socialmedia={{ twitter: agency.twitter, facebook: agency.facebook, linkedin: agency.linkedIn }}
                      mainContact={agency.mainContact}
                      faxNumber={agency.faxNumber}
                      mobilePhone={agency.mobilePhone}
                      websiteChatURL={agency.websiteChatURL}
                      googleChatURL={agency?.googleChatUsername}
                      skypeChatURL={agency?.skypeUsername}
                      rawPhoto={agency.coverPhotoRaw}
                      hideQuoteOption={agency?.agencyModel?.hideQuoteOption}
                      agencyWebsite={agency.agencyModel.agencyWebsite}
                    />
                  </>
                : null
              }
            </Content>
            <Content className="content-container">
              {/* left side column with agents-list */}
              <GridRow gutters>
                {agency
                  ? <GridCol sm={12} md={6} lg={3}>
                      <div className="agent-cards-heading">
                        <Heading id="our-team" type="h3-light" className="agency-info-page-heading">Our team</Heading>
                        {agentListLength < 0 ? null : <Caption>{agentListLength > 0 ? <div>{`${agentListLength} Agent${agentListLength > 1 ? "s": ""}`}</div> : null}</Caption>}
                      </div>
                      {renderPagination()}
                      <div className="agent-cards-block">
                        <div className="agent-cards">
                      
                          {!agentListLoaded ?
                          <>
                          {renderPlaceholderCards()}
                          </>
                          :
                            agents.filter((item:any, index:any)=> index >= currentPage * itemsPerPage && index < (currentPage + 1) * itemsPerPage ).map((agent: any) =>
                            <AgentCard
                              agencyID={agent.agentModel.agencyID}
                              agencyPrimaryName={formatAgencyName(agent.agentModel.agencyPrimaryName)}
                              agentID={agent.agentModel.agentID}
                              agentTitle={agent.agentModel.agentTitle}
                              name={nameCase(agent.agentModel.name)}
                              email={agent.agentModel.email}
                              phone={agent.agentModel.phone}
                              coverPhotoBlobId={agent.coverPhotoBlob_Id ? `/FAANG_App_Svc/resources/blobs/${agent.coverPhotoBlob_Id}` : agent?.photo}
                              yearStarted={agent.yearStarted}
                              summary={agent.summary}
                              key={agent.agentModel.agentID}
                              languages={agent.languages}
                            />
                          )}</div>
                      </div>
                    </GridCol>
                  : null
                }

                {/* middle column - about section */}
                <GridCol id="agency-info-details-wrapper" sm={12} md={agency?.agencyModel?.agentModel ? 6 : 12} lg={agency?.agencyModel?.agentModel ? 6 : 8}>
                  {agency
                    ? agency.about ||
                      agency.yearStarted ||
                      agency.productSpecialties ||
                      agency.carriers ||
                      agency.awardsList ||
                      agency.languages ||
                      agency.affiliations ||
                      agency.photos
                        ? <AboutSection
                            about={agency.about}
                            experience={agency.yearStarted}
                            products={agency.productSpecialties}
                            carriers={agency.carriers}
                            awards={agency.awardsList}
                            articles={agency.articles}
                            languages={agency.languages}
                            affiliations={agency.affiliations}
                            photos={agency.photos}
                          />
                        : <>
                            <Heading type="h3-light" className="about-section-heading">
                              Buy insurance through an agent today
                            </Heading>
                            <p className="about-section">{Constants.defaultProfileText}</p>
                          </>
                    : null
                  }
                  {!isLoading && place && agency?.agencyModel?.showGoogleReviews
                    ? <>
                        <div className="reviews-title-row">
                          <div>
                            <Heading type="h3-light" className="about-section-heading">Reviews</Heading>
                            <Caption>Powered by Google</Caption>
                          </div>
                          <div className="agency-page-reviews" >
                            <div>
                              <span className="rating stars" style={{"--rating" : place?.rating ?? 0 } as React.CSSProperties}>
                                {place.rating?.toPrecision(2)}&nbsp;&nbsp;
                              </span>
                            </div>
                            {agency.agencyModel.lat && agency.agencyModel.lng && place?.place_id
                              ? <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${agency.agencyModel.lat},${agency.agencyModel.lng}&query_place_id=${place?.place_id}`}
                                >
                                  <small>
                                    {`${place?.user_ratings_total} review${place?.user_ratings_total !== 1 ? 's' : ''}`}
                                  </small>
                                </a>
                              : null
                            }
                          </div>
                        </div>
                        {place?.place_id
                          ? <Reviews
                              {... { placeid: place.place_id,
                                      lat: agency.agencyModel.lat,
                                      lng: agency.agencyModel.lng
                                    }
                              }
                            />
                          : null
                        }
                      </>
                    : null
                  }
                </GridCol>
                {agency?.agencyModel?.lat && agency?.agencyModel?.lng
                  ? <GridCol md={12} lg={agency.agencyModel.agentModel ? 3 : 4}>
                      <OfficeLocation
                        primaryOffice={
                          {lat: agency?.agencyModel?.lat,
                            lng: agency?.agencyModel?.lng,
                            officeName: formatAgencyName(agency?.agencyModel?.agencyPrimaryName),
                            addressLine1: fixCase(agency?.agencyModel?.agencyAddress1),
                            addressLine2: `${fixCase(agency?.agencyModel?.agencyCity)}, ${agency?.agencyModel?.agencyState} ${agency?.agencyModel?.agencyZip}`
                          }
                        }
                        otherOffices={agency?.otherOffices}
                      />
                      <Link
                        target="_blank"
                        href={`${faapmAgencyEditLink}/${agency.agencyModel.agencyID}`}
                      >
                        Is this your agency? Edit the profile
                      </Link>
                    </GridCol>
                  : null
                }
              </GridRow>
            </Content>
            <MobileNav showAgents={true} />
          </>
        : <div className="agency-page-alert">
            <Alert highlightType="negative">
              {error.message}
              <Link variant="standalone" caretPosition="none" href="/find-an-insurance-agency">
                Try a new search
              </Link>
            </Alert>
          </div>
      }
    </div>
  );
}
export default AgencyInfoPage;
