import { Content, GridCol, GridRow, Alert, Link } from "@lmig/lmds-react";
import React, { Suspense, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../PageHeader/PageHeader.css";
import getAgentInfo from "../../apiCalls/getAgentInfo";
import getAgencyInfo from "../../apiCalls/getAgencyInfo";
import getFaaPmEditLink from "../../apiCalls/getFaaPmEditLink";
import AboutSection from "./AboutSection/AboutSection";
import OfficeLocation from "../OffliceLocation/OfficeLocation";
import MobileNav from "../MobileNav/MobileNav";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { fixCase, formatAgencyName, formatAgentOperatingHours, formatPhone } from "../../helpers/StringFormatter";
import { AppContext } from "../../App";
import { CircularProgressSpinner } from "@lmig/sales-ui-components";
import { Helmet, HelmetProvider} from 'react-helmet-async';

const PageHeader = React.lazy(() => import("../PageHeader/PageHeader"));

const AgentInfoPage = (props : any) => {
  // probably want to sanitize these params? or does useParams do that??
  const params: any = useParams();
  const [agent, setAgent] = useState<any>();
  const [agency, setAgency] = useState<any>();
  let [faapmAgentEditLink, setfaapmAgentEditLink] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ wasreturned: boolean, message?: string }>({ wasreturned: false });
  /*app context */
  const context = useContext(AppContext);

  const structuredData = () => {
    /* developers.google.com/search/docs/advanced/structured-data/local-business */
    let data =
    [ {
      "@context": "https://schema.org",
      "@type": "InsuranceAgency",
      "image": `/FAANG_App_Svc/resources/blobs/${agent?.coverPhotoBlob_Id}`,
      "name": `${fixCase(agent?.agentModel?.name)} - Safeco Insurance Agent, ${fixCase(agency?.agencyModel?.agencyCity)}, ${agency?.agencyModel?.agencyState}`,
      "description": `${agent?.about}`,
      "address": {
        "@type": "PostalAddress",
        "streetAddress": `${fixCase(agency?.agencyModel?.agencyAddress1)}`,
        "addressLocality": `${fixCase(agency?.agencyModel?.agencyCity)}`,
        "addressRegion": `${agency?.agencyModel?.agencyState}`,
        "postalCode": `${agency?.agencyModel?.agencyZip}`,
        "addressCountry": "US"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": `${agency?.agencyModel?.lat}`,
        "longitude": `${agency?.agencyModel?.lng}`
      },
      "url": `${agency?.agencyModel?.agencyWebsite}`,
      "telephone": `${agent?.agentModel?.phone}`,
    }, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": {
      "@type": "Question",
      "name": "What are our hours?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `${formatAgentOperatingHours(agent.operatingTimes)}`
      }
      }
    } ]

    return JSON.stringify(data)
  }
  
  const agentDescription = ()=>{
    return  `Call ${!agent?.preferredName ? fixCase(agent?.agentModel?.name) : fixCase(agent?.preferredName)} at ${formatPhone(!agent?.preferredPhone ? agent?.agentModel?.phone : agent?.preferredPhone)}, a Safeco independent agent with ${formatAgencyName(agency?.agencyModel?.agencyPrimaryName)}, in ${fixCase(agency?.agencyModel?.agencyCity)}, ${agency?.agencyModel?.agencyState} for a quote today!`
  }

  const imageUrl = ()=>{
    return `${agent.coverPhotoBlob_Id? window.location.host + `/FAANG_App_Svc/resources/blobs/`+ agent.coverPhotoBlob_Id : window.location.host + "/find-an-insurance-agency/favicon.ico"}`
  }

  useEffect(() => {
    window.scrollTo(0,0);
    async function initInfo() {
      setIsLoading(true);


      let res = await getAgentInfo(params.agencyId, params.agentId);
      if (res && !(res.statusText === "Internal Server Error" || res.statusText === "No Content")) {
        setAgent(res);
        document.title = fixCase(res?.agentModel?.name);
      }
      else if (res.statusText === "Internal Server Error")
        setError({ wasreturned: true, message: "An error occured while looking up this agent's profile. Please try again later." })

      else if (res.statusText === "No Content")
        setError({ wasreturned: true, message: "No content was found for this agent." })
      setIsLoading(false);
      getFaaPmEditLink().then(res=>res.json()).then((obj)=>obj.details).then((resultArray)=>{
        resultArray.forEach((result:any)=>{
          if(result.label === "agentUrl"){
            setfaapmAgentEditLink(result.url);
          }
        })          
      })
    }
    //if not from preview and if not from iframe
    if (!props?.agent && window.self === window.top) {
      initInfo();
    }
    else if (props?.agent) {
      setAgent(props?.agent);
    }
  }, [params, props?.agent]);

  useEffect(() => {
    async function initInfo() {
      if (context.agency)
        setAgency(context.agency);
      else if (params.agencyId) {
        let res = await getAgencyInfo(params.agencyId);
        if (res)
          setAgency(res);
      }
    }
    initInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  return (
    <div>
      {!error.wasreturned ? <><Content className="full-bleed">
      {isLoading ? <CircularProgressSpinner aria-label="Loading agent profile" size="64" /> : null}
        <Suspense fallback={<div>Loading...</div>}>
          {agent?.agentModel
            ? <>
            { !agent?.preview ?
            <Breadcrumb searchResults="" agencyID={params.agencyId} agencyName={formatAgencyName(agency?.agencyModel?.agencyPrimaryName)} currentPage={fixCase(agent?.agentModel?.name)} />
              : <></>
            }
                <HelmetProvider>
                  <Helmet>
                    <script type="application/ld+json">{structuredData()}</script>
                    <meta name="description"               content={agentDescription()} />
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:title"              content={!agent?.preferredName ? fixCase(agent?.agentModel?.name) : fixCase(agent?.preferredName)}/>
                    <meta property="og:description"        content={agentDescription()} />
                    <meta property="og:image"              content={imageUrl()}/>
                  </Helmet>
                </HelmetProvider>
                <PageHeader
                  agencyID={params.agencyId}
                  agentID={agent?.agentModel?.agentID}
                  addressOne={fixCase(agency?.agencyModel?.agencyAddress1)}
                  addressTwo={agency?.agencyModel ? `${fixCase(agency.agencyModel.agencyCity)}, ${agency.agencyModel.agencyState} ${agency.agencyModel.agencyZip}` : ''}
                  blobId={agent?.coverPhotoBlob_Id ?? agency?.agencyModel?.agentModel.filter((x:any)=> x.agentID === agent?.agentModel?.agentID)[0]?.coverPhotoBlobId}
                  rawPhoto={agent?.coverPhotoRaw}
                  email={agency?.agencyModel?.agentModel.filter((x:any)=> x.agentID === agent?.agentModel?.agentID)[0]?.email}
                  name={!agent?.preferredName ? fixCase(agent?.agentModel?.name) : fixCase(agent?.preferredName)}
                  phone={!agent?.preferredPhone ? agent?.agentModel?.phone : agent?.preferredPhone}
                  mobilePhone= {agent?.otherPhone}
                  about={agent?.summary}
                  googleChatURL={agent?.googleChat}
                  skypeChatURL={agent?.skype}
                  yearStarted={agent?.yearStarted}
                  socialmedia={{ twitter: agent?.twitter, facebook: agent?.facebookUrl, linkedin: agent?.linkedInUrl }}
                  operatingTimes={agent?.operatingTimes}
                  hideQuoteOption={agency?.agencyModel?.hideQuoteOption}
                  agencyWebsite={agency.agencyModel.agencyWebsite}
                />
              </>
            : null}
        </Suspense>
      </Content>
        <Content>
          <GridRow gutters>
            {agent && !isLoading ? (
              <GridCol sm={12} md={agency?.agencyModel ? 9 : 12}>
                <div>
                  <AboutSection
                    summary={agent?.summary}
                    yearStarted={agent?.yearStarted}
                    languages={agent?.languages}
                    products={agent?.productSpecialties}
                    currentJobs={agent?.currentJobs}
                    pastJobs={agent?.pastJobs}
                    educations={agent?.educations}
                    specialties={agent?.specialties}
                    groups={agent?.groupsAndAssociations}
                  />
                </div>
              </GridCol>
            ) : null}

            {agency?.agencyModel && !isLoading ?
              <GridCol sm={12} md={3}>
                <OfficeLocation
                  primaryOffice={{
                    lat: agency?.agencyModel?.lat,
                    lng: agency?.agencyModel?.lng,
                    officeName: formatAgencyName(agency.agencyModel.agencyPrimaryName),
                    addressLine1: fixCase(agency.agencyModel.agencyAddress1),
                    addressLine2: `${fixCase(agency.agencyModel.agencyCity)}, ${agency.agencyModel.agencyState} ${agency.agencyModel.agencyZip}`
                  }}
                  otherOffices={agency?.otherOffices}
                />
                <Link target="_blank" href={`${faapmAgentEditLink}/${params.agencyId}/${agent?.agentModel?.agentID}`}>Is this you? Edit the profile</Link>
              </GridCol>
              : null}
          </GridRow>
        </Content>
        <MobileNav showAgents={false} /></> :
        <div className="agency-page-alert">
          <Alert highlightType="negative">{error.message} <Link variant="standalone" caretPosition="none" href="/find-an-insurance-agency"> Try a new search</Link>
          </Alert>
        </div>}
    </div>
  )
}

export default AgentInfoPage;
