import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LoadGoogleMapsApi } from './helpers/LoadGoogleMapApi';

const renderApp = async () => {
  try{
    
    await LoadGoogleMapsApi();
    ReactDOM.render(
      <React.StrictMode>
        <App />
        {/* <GoogleMapsComponent /> */}
      </React.StrictMode>,
      document.getElementById("root")
    );
  }catch (error) {
    console.error('Error loading Google Maps API:', error);
  }
}
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
