import { IconPhone, IconEmail, IconChat, IconLocation, Heading, Link, Badge, IconDocument, IconButton, IconFacebook, IconLinkedin, IconTwitter, IconSmartphone, IconAgent } from "@lmig/lmds-react";
import { useState, useEffect } from "react";
import { formatPhone } from "../../helpers/StringFormatter";
import QuoteModal from "../QuoteModal/QuoteModal";
import "./PageHeader.css";
import eliteBadge from "../AgencyCard/assets/Ignite-White-Rocket.svg"; 

function PageHeader(props: IContactInfo) {
    let [businessHours, setBusinessHours] = useState<{ isOpen: boolean, currentDay?: string }>();
    useEffect(() => {
        for (var i = 0; i < (props?.operatingTimes?.length || 0); i++) {
            if (props.operatingTimes) {
                let day: string = props.operatingTimes[i].day;
                if (isToday(props.operatingTimes[i].day)) {
                    if (props.operatingTimes[i]?.startTime !== '-----' && props.operatingTimes[i]?.start !== '-----') {
                        setBusinessHours({ isOpen: true, currentDay: day }); return;
                    }
                }
            }
        }
        setBusinessHours({ isOpen: false })
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const isHoursExists = (): boolean => {
        let count = 0 ;
        for (var i = 0; i < (props?.operatingTimes?.length || 0); i++) {
            if (props.operatingTimes !== undefined && props.operatingTimes[i].start === '-----' && props.operatingTimes[i].stop === '-----') {
                count++;
            }
        }
        if(count === 7)
            return false;
        else
            return true;
    }

    const isToday = (dayOfWeek: string): boolean => {
        let today: number = new Date().getDay();
        switch (dayOfWeek.toLowerCase()) {
            case "mon":
                return today === 1;
            case "tue":
                return today === 2;
            case "wed":
                return today === 3;
            case "thu":
                return today === 4;
            case "fri":
                return today === 5;
            case "sat":
                return today === 6;
            case "sun":
                return today === 7;
        }
        return false;
    }


    return (
        <>
            <div className="agency-banner-top-row">
                <div className="agency-image-name">
                    {(props.blobId || props.rawPhoto) ? <img id="agency-banner-agency-image" className="profile-pic-container" object-fit src={props.rawPhoto ? props.rawPhoto : "/FAANG_App_Svc/resources/blobs/" + props.blobId} alt={props.name} /> : null}
                    <div className="page-header-name">
                        <div className="eliteStatus">
                            {props.elite
                                ? <div id="elite-image">
                                    <div>Safeco Elite Agency</div>
                                    <img src={eliteBadge} alt="Safeco Elite Agency">
                                    </img>
                                    </div>
                                : null
                            }
                        </div>
                        <Heading type="h1">{props.name}</Heading>
                    </div>
                </div>
                <div className="agency-banner-button-section">
                    <QuoteModal agencyID={props.agencyID.slice(-8)} zipCode={props.addressTwo} hideQuoteOption={props.hideQuoteOption} agencyWebsite={props.agencyWebsite} ></QuoteModal>
                </div>
            </div>
            {/* left portion of the banner */}
            <div id="agency-banner-img-and-contact" style={props?.operatingTimes ? {} : { marginBottom: "2rem" }}>
                <div className="contact-info-top-row">
                    <div className="contact-info-col">
                        {props.phone ?
                            <div>
                                <div className="agency-banner-contact-label">Phone</div>
                                <div>
                                    <Link
                                        variant="standalone"
                                        caretPosition="none"
                                        id="agency-banner-phone"
                                        href={"tel:" + props.phone}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <IconPhone size="16" color="teal" className="contact-link-icon" />
                                        {formatPhone(props.phone)}
                                    </Link>
                                    {props.mainContact || props.mobilePhone || props.faxNumber || props.websiteChatURL || props.googleChatURL || props.skypeChatURL ?
                                        <div className="contact-section-divider small-contact-section">
                                            {props.mainContact ?
                                                <div>
                                                    <IconAgent size="16" color="teal" title="Mobile" className="contact-link-icon" />
                                                    <span>{props.mainContact}</span>
                                                    <span className="small-header"> - Main Contact</span>

                                                </div> : null}

                                            {props.mobilePhone ?
                                                <div>
                                                    <IconSmartphone size="16" color="teal" title="Mobile" className="contact-link-icon" />
                                                    {formatPhone(props.mobilePhone)}
                                                </div> : null}
                                            {props.faxNumber ?
                                                <div>
                                                    <IconDocument size="16" color="teal" title="Fax" className="contact-link-icon" />
                                                    {formatPhone(props.faxNumber)}
                                                </div> : null}
                                            {props.websiteChatURL ?
                                                <div>
                                                    <Link variant="standalone" target="_blank" caretPosition="none" href={props.websiteChatURL.indexOf("https") > -1 ? props.websiteChatURL : `https://${props.websiteChatURL}`}>
                                                        <IconChat size="16" color="teal" className="contact-link-icon" />
                                                        <span className="small-header">Chat/text us</span>
                                                    </Link>

                                                </div> : null}
                                            {props.googleChatURL ?
                                            <div>
                                                <Link variant="standalone" target="_blank" caretPosition="none" href={props.googleChatURL.indexOf("https") > -1 ? props.googleChatURL : `https://${props.googleChatURL}`}>
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.4 499.98" style={{verticalAlign:'middle'}} width="18px" height="18px"><title>google-chat</title><path className="cls-1" d="M121.24,275.69V113.19h-71a37.86,37.86,0,0,0-37.8,37.9V487c0,16.9,20.4,25.3,32.3,13.4l78.1-78.1h222.4a37.77,37.77,0,0,0,37.8-37.8v-71H159.14A37.86,37.86,0,0,1,121.24,275.69Z" transform="translate(-12.44 -5.99)"/><path className="cls-2" d="M454,6H159.14a37.77,37.77,0,0,0-37.8,37.8v69.4h223.9A37.77,37.77,0,0,1,383,151v162.4h71a37.77,37.77,0,0,0,37.8-37.8V43.79A37.77,37.77,0,0,0,454,6Z" transform="translate(-12.44 -5.99)"/><path className="cls-3" d="M345.24,113.19h-224v162.4a37.77,37.77,0,0,0,37.8,37.8h223.9V151.09A37.71,37.71,0,0,0,345.24,113.19Z" transform="translate(-12.44 -5.99)"/></svg>
                                                    <span className="small-header chat-text">Google Chat/{props.googleChatURL}</span>
                                                </Link>

                                            </div> : null}
                                            {props.skypeChatURL ?
                                            <div>
                                                <Link variant="standalone" target="_blank" caretPosition="none" href={props.skypeChatURL.indexOf("https") > -1 ? props.skypeChatURL : `https://${props.skypeChatURL}`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{verticalAlign:'middle'}} width="18px" height="18px"><path fill="#03A9F4" d="M15.716 8.083c0-4.155-3.416-7.524-7.631-7.524-.444 0-.88.038-1.305.11A4.453 4.453 0 0 0 4.426 0C1.981 0 0 1.953 0 4.364a4.3 4.3 0 0 0 .608 2.207 7.464 7.464 0 0 0-.155 1.512c0 4.156 3.417 7.525 7.631 7.525a7.85 7.85 0 0 0 1.398-.126 4.46 4.46 0 0 0 2.093.518C14.019 16 16 14.046 16 11.637c0-.696-.166-1.354-.46-1.938a7.43 7.43 0 0 0 .176-1.616z"/><path fill="#FAFAFA" d="M11.999 11.679c-.353.493-.874.883-1.551 1.159-.669.274-1.47.415-2.384.415-1.096 0-2.015-.19-2.733-.566a3.547 3.547 0 0 1-1.256-1.1c-.325-.46-.488-.917-.488-1.36 0-.274.107-.514.318-.709.209-.195.477-.292.797-.292.262 0 .488.077.672.23.175.148.326.366.447.645.135.306.282.564.437.765.151.197.366.361.641.49.277.128.65.195 1.108.195.631 0 1.149-.134 1.537-.395.381-.255.565-.563.565-.939 0-.296-.097-.53-.294-.713a2.104 2.104 0 0 0-.814-.444 16.188 16.188 0 0 0-1.4-.342c-.793-.167-1.466-.364-2-.59-.547-.23-.989-.548-1.311-.945-.328-.406-.494-.913-.494-1.509 0-.568.174-1.08.518-1.522.341-.439.839-.782 1.482-1.015.633-.231 1.386-.347 2.239-.347.681 0 1.28.078 1.781.232.503.154.927.362 1.26.619.336.26.586.535.742.823.158.29.239.579.239.858 0 .269-.105.514-.313.726-.21.214-.474.322-.784.322-.282 0-.504-.069-.657-.202-.143-.125-.292-.32-.456-.598a2.507 2.507 0 0 0-.685-.836c-.257-.193-.685-.289-1.275-.289-.546 0-.992.108-1.322.322-.318.205-.473.441-.473.721 0 .171.05.314.153.437.108.132.261.245.455.341.2.099.407.179.614.235.212.058.567.145 1.056.256.618.13 1.185.277 1.687.436.509.159.947.356 1.307.587.365.235.654.535.86.895.206.363.31.808.31 1.326a2.833 2.833 0 0 1-.535 1.678z"/></svg>
                                                    <span className="small-header chat-text">Skype Chat/{props.skypeChatURL}</span>
                                                </Link>
                                            </div> : null}
                                        </div> : null}
                                </div>
                            </div>
                            : null}
                    </div>

                    <div className="contact-info-col">

                        {props.website?.trim() ?
                            <div className="agency-card-website">
                                <div className="agency-banner-contact-label">Website</div>
                                <Link
                                    variant="standalone"
                                    caretPosition="none"
                                    href={props.website?.trim() ? (props.website.indexOf("http") !== -1 ? props.website : `http://${props.website}`) : undefined  /*setting website protocol to http will redirect to https - some agency sites still use http*/}
                                    target="_blank">
                                    <svg className="page-header-icon" xmlns="http://www.w3.org/2000/svg" version="1.0" width="18px" height="18px" viewBox="0 0 24.000000 24.000000" preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" fill="#06748c" stroke="none">
                                            <path d="M94 213 c-66 -12 -92 -110 -42 -159 37 -37 99 -37 136 0 68 67 3 177 -94 159z m106 -88 c0 -56 -33 -76 -50 -30 -7 19 -17 25 -40 25 -36 0 -37 9 -4 35 14 11 31 25 37 32 16 16 57 -29 57 -62z m-105 -48 c18 -36 18 -37 3 -37 -24 0 -58 43 -58 73 0 30 0 30 20 12 11 -10 27 -32 35 -48z" />
                                        </g>
                                    </svg>
                                    {props.website}</Link>
                            </div>
                            : null}
                        {props.email?.trim() && props.website?.trim() ?
                            <div className="contact-section-divider">
                                <Link
                                    variant="standalone"
                                    caretPosition="none"
                                    href={"mailto:" + props.email}>
                                    <div className="agency-banner-icon-label">
                                      <IconEmail className="about-section-icon" />
                                      {props.email.toLowerCase()}
                                    </div>
                                </Link>
                            </div> : props.email?.trim() ? <div>
                                <div className="agency-banner-contact-label">Email</div>
                                <Link
                                    variant="standalone"
                                    caretPosition="none"
                                    href={"mailto:" + props.email}>
                                    <div className="agency-banner-icon-label"><IconEmail className="about-section-icon" />{props.email.toLowerCase()}</div>
                                </Link>
                            </div> : null}

                    </div>
                    {props.addressOne && props.addressTwo ? <div className="contact-info-col">
                        <div className="agency-banner-contact-label">Address</div>
                        <div className="address-section">
                            <IconLocation size="16" color="teal" className="page-header-icon" />
                            <div>
                                <div>{props.addressOne}</div>
                                <div>{props.addressTwo}</div>
                            </div>
                        </div>

                    </div> : null}
                    <div className="social-media-icons">
                        {props.socialmedia?.facebook ? (
                            <div className="facebook-icon-and-like-plugin">
                                <IconButton href={props.socialmedia?.facebook} target="_blank" rel="noreferrer">
                                    <IconFacebook className="about-section-icon" />
                                </IconButton>
                                <iframe src={`https://www.facebook.com/plugins/like.php?href=${props.socialmedia?.facebook}&width=108&layout=button_count&action=like&size=small&share=false&height=21&appId`} title="facebook" width="108" height="21" scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>) : null}
                        {props.socialmedia?.linkedin ? (
                            <IconButton href={props.socialmedia?.linkedin} target="_blank" rel="noreferrer">
                                <IconLinkedin className="about-section-icon" />
                            </IconButton>
                        ) : null}
                        {props.socialmedia?.twitter ? (
                            <IconButton href={props.socialmedia?.twitter} target="_blank" rel="noreferrer">
                                <IconTwitter className="about-section-icon" />
                            </IconButton>
                        ) : null}</div>
                </div>
                {/* show business hours if present */}
                {props?.operatingTimes && isHoursExists() ?
                    <div className="business-hours-container">
                        <div className={businessHours?.isOpen ? "agency-banner-contact-label open" : "agency-banner-contact-label closed"} >Business Hours</div>
                        <div className="business-hours-content">
                            <div className="business-hours">
                                {props?.operatingTimes.map((time:any, index) =>
                                    <div key={index}>
                                        {businessHours?.currentDay === time.day ? <Badge highlightType="positive">Open Now</Badge> : null}
                                        <div className={businessHours?.currentDay === time.day ? "today day-of-week-row" : "day-of-week-row"} key={index}>
                                            <span className="maps-and-hours-day-of-week">{time.day}</span>
                                            {time.startTime?.toString().includes("-") ? <span>Closed</span> : <span>{`${time.startTime ?? time.start} - ${time.stopTime ?? time.stop}`}</span>}
                                        </div>
                                    </div>
                                )}
                                <i>*Other hours available upon request</i>
                            </div>
                        </div>
                    </div> :
                     <div className="business-hours-container">
                     <div className="agency-banner-contact-label closed">Business Hours</div>
                     <div className="business-hours-content">
                          <Badge highlightType="informative">Call for business hours</Badge>
                         </div>
                    </div>}
            </div>
        </>
    );
}
export default PageHeader;
