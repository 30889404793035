import { LoadGoogleMapsApi } from '../helpers/LoadGoogleMapApi';

export class Constants {
  static readonly defaultProfileText: string = "When you buy insurance through an independent agent, you get ease, choice, and advice. The ease of an insurance professional summarizing your options for you and helping you make sense of it all. The choice of many carriers so you can choose what fits you best. The advice of someone who isn't obligated to sell you one particular carrier or one particular type of insurance coverage."
  static icon:google.maps.Symbol;
    static activeIcon:google.maps.Symbol;
     static initializeGoogleMapsConstants = async () => {
      await LoadGoogleMapsApi();
  /*map markers*/
  Constants.icon = {
    path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
    fillColor: '#99E5EA',
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: "#06748c",
    scale: .09,
    labelOrigin: new google.maps.Point(200, 200),
    anchor: new google.maps.Point(200, 500)
  }

  Constants.activeIcon = {
    path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
    fillColor: '#06748C',
    fillOpacity: 1,
    strokeColor: "#055060",
    strokeWeight: 1,
    scale: .09,
    labelOrigin: new google.maps.Point(200, 200),
    anchor: new google.maps.Point(200, 500)
  }
};
  /*map pin letter labels*/
  static readonly labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  static readonly ErrorMessages = {
    NoReultsWithFilter: "Your search produced no results for the selected language. Please try clearing or changing the language filter or call us at 800-472-3326",
    NoResults: "Your search produced no results. Please try again or call us at 800-472-3326",
    Default: "Your search produced an error. Please try again later or call us at 800-472-3326."
  }

  static readonly MegaMenus = {
    ShopInsurance: {
      Sections: [{
        Heading: "Auto & Vehicle",
        Links: [
          { label: "Car Insurance", url: "https://www.safeco.com/products/auto-insurance" },
          { label: "Motorcycle Insurance", url: "https://www.safeco.com/products/motorcycle-insurance" },
          { label: "Boat and Watercraft", url: "https://www.safeco.com/products/boat-insurance" },
          { label: "Classic Car", url: "https://www.safeco.com/products/classic-car-insurance" },
          { label: "Recreational Vehicle (RV)", url: "https://www.safeco.com/products/rv-insurance" },
          { label: "ATV & Off-Road Vehicle", url: "https://www.safeco.com/products/atv-insurance" },
          { label: "Gold Cart & Low-Speed Vehicle", url: "https://www.safeco.com/products/golf-cart-insurance" },
          { label: "RightTrack", url: "https://www.safeco.com/products/righttrack" },
        ]
      },
      {
        Heading: "Property",
        Links: [
          { label: "Homeowners", url: "https://www.safeco.com/products/homeowners-insurance" },
          { label: "Renters", url: "https://www.safeco.com/products/renters-insurance" },
          { label: "Condo", url: "https://www.safeco.com/products/condo-insurance" },
          { label: "Landlord", url: "https://www.safeco.com/products/landlord-insurance" },
          { label: "Home Warranty", url: "https://www.safeco.com/products/home-warranty" },
        ]
      },
      {
        Heading: "Other Insurance",
        Links: [
          { label: "Umbrella", url: "https://www.safeco.com/products/umbrella-insurance" },
          { label: "Pet Health Insurance", url: "https://www.safeco.com/products/pet-health-insurance" },
          { label: "Identity Recovery", url: "https://www.safeco.com/products/identity-recovery-coverage" },
          { label: "Commercial Insurance", url: "https://business.libertymutualgroup.com/business-insurance" },
        ]
      }],
      Button: { label: "Find an agent", url: "https://www.safeco.com/find-an-agent" }
    },
    ForCustomers: {
      Sections: [
        {
          Heading: "Get answers",
          Links: [
            { label: "Customer support", url: "https://www.safeco.com/customer-resources/customer-support" },
            { label: "Find an agent", url: "https://www.safeco.com/find-an-agent" },
            { label: "En español", url: "https://www.safeco.com/customer-resources/espanol" },
          ]
        }, {
          Heading: "Claims help",
          Links: [
            { label: "Claims center", url: "https://www.safeco.com/claims" },
            { label: "Auto claims", url: "https://www.safeco.com/claims/auto-claims" },
            { label: "Home claims", url: "https://www.safeco.com/claims/home-claims" }]
        }, {
          Heading: "Resources",
          Links: [
            { label: "Safeco blog", url: "https://www.safeco.com/blog" },
            { label: "Make more happen", url: "https://www.safeco.com/make-more-happen" }]
        }],
      Button: { label: "Get the app", url: "https://www.safeco.com/customer-resources/mobile-voice-apps/safeco-mobile-app" }
    },
    ForAgents: {
      Sections: [{
        Heading: "Agent resources",
        Links: [
          { label: "Why become a Safeco agent?", url: "https://www.safeco.com/agent-resources/become-independent-agent" },
          { label: "Agents in the community", url: "https://www.safeco.com/agent-resources/agents-community" },
          { label: "Apply to sell Safeco Insurance", url: "https://www.safeco.com/agent-resources/apply" }]
      }],
      Button: { label: "Safeco Now®", url: "https://now.agent.safeco.com/start" }
    }
  }
}
// Initialize Google Maps constants
Constants.initializeGoogleMapsConstants();